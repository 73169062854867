import React, { Component, useState } from 'react';
import '../css/App.css';

import orange from '../media/orange.png';

function Header() {

    const [copied, setCopied] = useState(false);

    function copytoClipboard() {
        // reset the copy animation
        setCopied(false);

        navigator.clipboard.writeText("songeun.you7@gmail.com").then(function() {
            setCopied(true);
            setTimeout(() => setCopied(false), 2500);
        }.bind(this), function() {
            console.log("Failed to copy");
        });
    }

    return (
        <div id="header">
            <div id="icon">
                <img src={orange} id="orange"/>
            </div>
            <div id="intro-content">
                <h1 className="name">Song You</h1>
                <p className="role"> Software engineer with a soft spot for design & concept art</p>
                <div className="social-links">
                    <a href="https://github.com/songeunyou" target="blank">github</a>
                    <a style={{cursor: "pointer"}} onClick={() => copytoClipboard()}>
                        { copied ? "copied email!" : "email" }
                    </a>
                    <a href="https://m.me/songyouu" target="blank">messenger</a>
                </div>
            </div>
        </div>
    );
}

export default Header;
