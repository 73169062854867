import React, { Component, useState } from 'react';

import d1 from '../media/d1.jpg';
import d2 from '../media/d2.jpg';
import d3 from '../media/d3.jpg';
import d4 from '../media/d4.jpg';

import day1 from '../media/day1.jpg';
import day2 from '../media/day2.jpg';
import day3 from '../media/day3.jpg';
import day4 from '../media/day4.jpg';

function Dev() {
    let dev = [
        {
            title: "Jouncer",
            description: "A community of creators; view and share side projects",
            url: "https://jouncer.com/"
        },
        {
            title: "Letter to Myself",
            description: "A text editor to combat negative self-talk",
            url: "https://songeunyou.github.io/letter-to-myself/"
        },
        {
            title: "Copypasta",
            description: "A Chrome extension to easily copy + paste text snippets",
            url: "https://chrome.google.com/webstore/detail/copypasta/lgapkbbdlekmaeomdpndkpkmckghjnmh"
        },
        {
            title: "Plant Something",
            description: "A digital garden powered by the beauty of CSS animations!",
            url: "https://songeunyou.github.io/plant-something/"
        }
    ]
    return (
        <div id="dev">
            <div className="dev-links">
                {dev.map(d =>
                    <div className="project">
                        <a href={d.url} target="blank">
                            <h2>{d.title}</h2>
                            <p>{d.description}</p>
                        </a>
                    </div>
                )}
            </div>
            <p className="previous">Previously contributed to: &nbsp;
                <a href="https://www.slatejs.org/" target="blank">Slate.js</a>, &nbsp;
                <a href="https://uoftblueprint.org/" target="blank">UofT Blueprint</a>, &nbsp;
                <a href="https://yourenext.ca/" target="blank">You're Next Career Network</a>, &nbsp;
                <a href="https://stagekeep.com/" target="blank">StageKeep</a>
            </p>
        </div>
    );
}

function Design() {
    let des = [d1, d2, d3, d4]
    return (
        <div id="design">
            {des.map(d =>
                <div className="images">
                    <img src={d}/>
                </div>
            )}
        </div>
    );
}

function Illustration() {
    let ill = [day1, day2, day3, day4]

    return (
        <div id="illustration">
            {ill.map(i =>
                <div className="images">
                    <img src={i}/>
                </div>
            )}
        </div>
    );
}

function About() {
    let about = [
        {
            question: "Problems worth solving?",
            answer: [
                "Business incentives that are misaligned with the wellbeing of society/nature. Misfits Market turning food waste into profit, ISAs, and Unsplash's anti-ad revenue strategy are examples of business models that I find hope in."
            ]
        },
        {
            question: "Current interests?",
            answer: [
                "I’m reading “A Chronicle of Aviation” and I’m blown away by how humans unlocked the secret of flight. We went from 1903 when the Wright brothers flew an oversized kite to having fully-functioning WWI combat planes only 10 years later.",
                "Bullish on the Metaverse. There are some stunning indie games out there and I definitely see a future where we live in immersive, connected digital worlds ♥"
            ]
        }
    ]
    return (
        <div id="about">
            {about.map(a =>
                <div className="faq">
                    <p className="question">{a.question}</p>
                    <div className="answers">
                        {
                            a.answer.map(m =>
                                <p className="answer">{m}</p>
                            )
                        }
                    </div>
                </div>
            )}
        </div>
    );
}

function Section(props) {

    const [closed, setAccordian] = useState(true);

    function MediaSwitch(props) {
        switch(props.sectionName) {
            case "development":
                return <Dev/>
            case "design":
                return <Design/>
            case "illustration":
                return <Illustration/>
            case "about me":
                return <About/>
        }
    }

    return (
        <div className="section" style={{backgroundColor: props.bg}} onClick={() => setAccordian(false)}>
            <div className="section-content">
                <div className="description">
                    <h2 className="title" style={{color: props.font}}>{props.title}</h2>
                    <div className="blurb" style={{color: props.font}}>
                        {props.blurb}
                        {props.link ? <a href={props.link} style={{color: props.font}} target="blank">{props.linkTitle}</a> : ""}
                    </div>
                </div>
                <div className="content">
                    <MediaSwitch sectionName={props.title}/>
                </div>
            </div>
        </div>
    );
}

export default Section;
