import React, { Component } from 'react';

class Essays extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div id="essays">
                <h2>Essays</h2>
                <div className="bubble">
                    <p className="bubble-title">Missing the Point</p>
                    <p className="bubble-date">Aug 12 2020</p>
                </div>

                <div className="bubble">
                    <p className="bubble-title">Genocide of Dissent</p>
                    <p className="bubble-date">Aug 06 2020</p>
                </div>

                <div className="bubble">
                    <p className="bubble-title">The Crappy Marker</p>
                    <p className="bubble-date">Aug 05 2020</p>
                </div>

                <div className="bubble">
                    <p className="bubble-title">Why I Write</p>
                    <p className="bubble-date">Jul 28 2020</p>
                </div>
            </div>
        );
    }
}

export default Essays;
