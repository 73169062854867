import React, { Component } from 'react';
import './css/App.css';

import Header from './components/Header.js';
import Essays from './components/Essays.js';
import Section from './components/Section.js';
import Exit from './components/Exit.js';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="App">
                <div className="homepage">
                    <Header/>

                    <div className="content">
                        <Section
                            title="development"
                            bg="#EEECE7"
                            font="#3C3B39"
                            linkTitle="✳ github"
                            link="https://github.com/songeunyou"
                            blurb="React is my bread & butter, SCSS is my jam. I also build things with Python and C++. I’m currently playing with Processing to make generative art."/>
                        <Section
                            title="design"
                            bg="black"
                            font="#EEECE7"
                            linkTitle="@ordinarylines"
                            link="https://www.instagram.com/ordinarylines/"
                            blurb="Fiddling around with graphics keeps my design chops sharp. You can check out my ongoing work on instagram."/>
                        <Section
                            title="illustration"
                            bg="#FFFEFA"
                            font="#3C3B39"
                            linkTitle="@song.psd"
                            link="https://www.instagram.com/song.psd/"
                            blurb="Drawing has done more to develop my curiosity for the world than anything else has. This year, I want to improve my visual library, so I'll be studying more vehicles, clothing, and environments."/>
                        <Section
                            title="about me"
                            bg="#EEECE7"
                            font="#3C3B39"
                            blurb=""/>
                    </div>

                    <Exit/>
                </div>
            </div>
        );
    }
}

export default App;
