import React, { Component, useState } from 'react';
import orange from '../media/orange.png';

function Exit() {
    const [copied, setCopied] = useState(false);

    function copytoClipboard() {
        // reset the copy animation
        setCopied(false);

        navigator.clipboard.writeText("songeun.you7@gmail.com").then(function() {
            setCopied(true);
            setTimeout(() => setCopied(false), 2500);
        }.bind(this), function() {
            console.log("Failed to copy");
        });
    }

    return (
        <div id="exit">
            <img src={orange} className="orange"/>
            <h1>Thanks for Visiting!</h1>
            <div className="social-links">
                <a href="https://github.com/songeunyou" target="blank">github</a>
                <a style={{cursor: "pointer"}} onClick={() => copytoClipboard()}>
                    { copied ? "copied email!" : "email" }
                </a>
                <a href="https://m.me/songyouu" target="blank">messenger</a>
                <a href="https://www.instagram.com/ordinarylines/" target="blank">design</a>
                <a href="https://www.instagram.com/song.psd/" target="blank">illustration</a>
            </div>
        </div>
    );
}

export default Exit;
